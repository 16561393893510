<template>
  <v-container
    style="justify-content: center; display: flex; height: 100%; background: #FFFFFF 0% 0% no-repeat padding-box; opacity: 1;">

    <div class="text-center">

      <b class="valor"> VALOR TOTAL A PAGAR
      </b>

      <br>

      <div style="display: flex;">
        <hr style="width: 50px; height: 0px; border: 1px solid #D0D0D0; opacity: 1; margin:auto;"><b class="precio">{{
          formatPrice(priceTotal)
        }}</b>
        <hr style="width: 50px; height: 0px; border: 1px solid #D0D0D0; opacity: 1; margin:auto;">
      </div>

      <br>
      <br>
      <br>

      <template>
        <v-data-table :footer-props="{ 'items-per-page-text': '' }" :headers="headers" :items="patentes"
          :items-per-page="5" class="elevation-1">
          <template v-slot:top>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">Estás seguro de eliminar este producto?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">Confirmar</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>

          <template v-slot:item.price="{ item }">
            <label style="margin:auto">{{ formatPrice(item.price) }}</label>
          </template>

        </v-data-table>
      </template>


      <br>

      <b class="valor"> ¿Quieres agregar otro vehículo?
      </b>

      <br>
      <br>
      <br>

      <v-row style="justify-content: center;">

        <v-btn
          style="width: 30px; height: 64px; background: #FFFFFF 0% 0% no-repeat padding-box; box-shadow: 0px 0px 10px #2D387C; border: 1px solid #DEDEDE; border-radius: 30px;
                  opacity: 1; text-align: center; font: normal normal normal 22px/27px Arial; letter-spacing: 0.66px; color: #D74E80; opacity: 1; margin-left: -1%;"
          @click="NewCarro()">
          Si
        </v-btn>

        <v-btn v-if="priceTotal != 0"
          style="width: 30px; height: 64px; background: #FFFFFF 0% 0% no-repeat padding-box; box-shadow: 0px 0px 10px #2D387C; border: 1px solid #DEDEDE; border-radius: 30px;
                  opacity: 1; text-align: center; font: normal normal normal 22px/27px Arial; letter-spacing: 0.66px; color: #D74E80; opacity: 1; margin-left: 5%;"
          @click="getToken()">
          No
        </v-btn>

      </v-row>

      <br>
      <br>

      <v-form ref="form" v-model="valid">

        <v-col v-if="token" style="max-width: 320px; margin:auto;">
          <b class="valor"> Ingrese rut pagador </b>
          <br>
          <br>
          <v-text-field v-model="newRut" :rules="rutRules" label="Ingrese rut pagador" required filled solo
            :class="labelsSup" hide-details="auto" @change="changeRut">
          </v-text-field>
        </v-col>

        <br>

        <v-col v-if="token">
          <v-checkbox style="max-width: 620px; margin-left: 5%;" v-model="conditions" :rules="conditionsRules"
            label="AUTORIZACION PARA TRATAMIENTO DE DATOS PERSONALES: Autorizo que mi información personal sea recopilada, almacenada, tratada y divulgada tanto por Scotiabank como por la Compañía de Seguros, 
            según la normativa vigente y conforme con el Acuerdo de Privacidad contenido en www.scotiabankchile.cl y las políticas propias de la compañía aseguradora." required dense>
          </v-checkbox>
        </v-col>

        <br>
        <br>

      </v-form>

      <b v-if="token" class="valor"> Puedes pagar tu SOAP con: </b>

      <br>
      <br>

      <div v-if="token" class="webpay">

        <br>
        <v-img contain :src="webPayLogo" style="width: 300px; height: 135px; margin: auto;" />

      </div>

      <br>
      <br>
      <br>

      <!-- <form :action="webPayUrl" method="POST" ref="form_payment">
        <input type="hidden" name="token_ws" :value="token" />
        <v-btn v-if="token"
          style="width: 290px; height: 64px; background: #FFFFFF 0% 0% no-repeat padding-box; box-shadow: 0px 0px 10px #2D387C; border: 1px solid #DEDEDE; border-radius: 30px;
                  opacity: 1; text-align: center; font: normal normal normal 22px/27px Arial; letter-spacing: 0.66px; color: #D74E80; text-transform: uppercase; opacity: 1;"
          type="submit" value="Pagar" @click="submitPago">
          Ir a pagar
        </v-btn>
      </form> -->

      <div>

        <v-btn v-if="token"
          style="width: 290px; height: 64px; background: #FFFFFF 0% 0% no-repeat padding-box; box-shadow: 0px 0px 10px #2D387C; border: 1px solid #DEDEDE; border-radius: 30px;
                  opacity: 1; text-align: center; font: normal normal normal 22px/27px Arial; letter-spacing: 0.66px; color: #D74E80; text-transform: uppercase; opacity: 1;"
          type="submit" value="Pagar" @click="submitPago('exitoso')">
          Flujo de pago
        </v-btn>

        <br>
        <br>

        <v-btn v-if="token"
          style="width: 290px; height: 64px; background: #FFFFFF 0% 0% no-repeat padding-box; box-shadow: 0px 0px 10px #2D387C; border: 1px solid #DEDEDE; border-radius: 30px;
                  opacity: 1; text-align: center; font: normal normal normal 22px/27px Arial; letter-spacing: 0.66px; color: #D74E80; text-transform: uppercase; opacity: 1;"
          type="submit" value="Pagar" @click="submitPago('fallido')">
          Flujo de rechazo
        </v-btn>

      </div>


    </div>

  </v-container>
</template>

<script>
import webPayLogo from "@/assets/images/webpay.png";
import RutUtils from "../utils/rut.utils";
import ShieldService from "../services/shield.service";
import swal from 'sweetalert';

export default {
  name: "PlanForm",
  props: ["carData", "propietarioData", "keygen", "token", "patentes", "priceTotal", "plan"],
  data: () => ({
    formatter: new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }),
    webPayLogo,
    conditions: null,
    typeCliente: null,
    webPayUrl: process.env.VUE_APP_WEBPAY_URL,
    newRut: "",
    dialogDelete: false,
    dialog: false,
    editedIndex: -1,
    editedItem: {
      patenteNumber: '',
      typeVehicle: '',
      modelVehicle: '',
      personEmail: '',
      price: 0,
    },
    defaultItem: {
      patenteNumber: '',
      typeVehicle: '',
      modelVehicle: '',
      personEmail: '',
      price: 0,
    },
    headers: [
      {
        text: 'Patente',
        sortable: false,
        value: 'patenteNumber',
        align: 'center',
      },
      { text: 'Tipo de Vehículo', value: 'typeVehicle', align: 'center', },
      { text: 'Modelo', value: 'modelVehicle', align: 'center', },
      { text: 'Correo', value: 'personEmail', align: 'center', },
      { text: 'Valor SOAP', value: 'price', align: 'center', },
      { text: 'Eliminar', value: 'actions', sortable: false, align: 'center', },
    ],
    confirmRules: [(v) => !!v || "Selecciona una opción"],
    rutRules: [
      (v) => !!v || "El rut del pagador es requerido",
      (v) => RutUtils.validateRut(v) || "Rut no válido",
    ],
    conditionsRules: [
      (v) => !!v || "Las condiciones deben ser aceptadas",
    ]
  }),
  mounted() {
    this.propietarioData.propietario = 'true'
    const query = this.$route.query;
    if (query.type) {
      switch (query.type) {
        case 'soap-clientes-scotia':
          this.typeCliente = 'clientes'
          break;
        case 'soap-no-clientes-scotia':
          this.typeCliente = 'no-clientes'
          break;
        case 'soap-pyme-scotia':
          this.typeCliente = 'pyme'
          break;
        case 'soap-colaboradores-scotia':
          this.typeCliente = 'colaboradores'
          break;

        default:
          break;
      }
    }
  },
  methods: {
    deleteItem(item) {
      this.editedIndex = this.patentes.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    async deleteItemConfirm() {
      await ShieldService.deleteCar(this.patentes[this.editedIndex]._id).then((response) => {
        if (response == 'Delete car') {
          this.patentes.splice(this.editedIndex, 1)
          this.closeDelete()
          this.$emit("reload");
        }
      })
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    back() {
      this.$emit("back");
    },
    validate(plan) {
      this.$emit("next-step", 2, plan);
    },
    NewCarro() {
      this.token = null,
        window.location.href = process.env.VUE_APP_DOMINIO + this.typeCliente + '?newCar=true';
    },
    async getToken() {
      var webPayToken = await ShieldService.create(this.keygen, this.plan._id);
      if (webPayToken.status == 'ok') {
        this.token = webPayToken.data.token;
      } else {
        swal({
          title: "UPS! Algo salió mal.",
          text: "Vuelta a internarlo nuevamente.",
          icon: "info",
          button: "Ok",
        }).then(() => {
          window.location.href = process.env.VUE_APP_DOMINIO + this.typeCliente
        });
      }
    },
    formatPrice(number) {
      return this.formatter.format(number);
    },
    async changeRut(rut) {
      this.propietarioData.rutPropietario = RutUtils.formatRut(rut);
      this.newRut = RutUtils.formatRut(rut);

      await ShieldService.updatePagador(this.keygen, this.propietarioData);

    },
    // async submitPago(event) {

    //   event.preventDefault();

    //   if (this.$refs.form.validate()) {
    //    this.$refs.form_payment.submit();
    //    return;
    //   }
    // }

    async submitPago(typeSolicitud) {

      try {

        const flujoSoapCoreResponse = await ShieldService.flujoSoapCore(typeSolicitud, this.token)

        console.log("respuesta flujo soap core: ", flujoSoapCoreResponse.data)

        if (flujoSoapCoreResponse.data.data.status == 'ok') {
          window.location.href = process.env.VUE_APP_DOMINIO + `finish?success=true&token=${flujoSoapCoreResponse.data.token}`;
        }

      } catch (error) {
        window.location.href = process.env.VUE_APP_DOMINIO + 'error'
      }

    }
  },
};
</script>


<style lang="scss" scoped>
.precio {
  width: 110px;
  height: 40px;
  text-align: center;
  font: Arial;
  font-size: 35px;
  letter-spacing: 0.53px;
  color: #D74E80;
  text-transform: uppercase;
  opacity: 1;
}

.patenteNumber {
  width: 138px;
  height: 40px;
  text-align: center;
  font: Arial;
  font-size: 35px;
  letter-spacing: 0.53px;
  color: #4E4E4E;
  text-transform: uppercase;
  opacity: 1;
}

.valor {
  width: 56px;
  height: 21px;
  text-align: center;
  font: Arial;
  font-size: 17px;
  letter-spacing: 0.26px;
  color: #2D387C;
  text-transform: uppercase;
  opacity: 1;
}

.patente {
  width: 76px;
  height: 21px;
  font: Arial;
  font-size: 17px;
  letter-spacing: 0.26px;
  color: #4E4E4E;
  text-transform: uppercase;
  opacity: 1;
}

.webpay {
  width: 80%;
  height: 206px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border: 1px solid #D5D5D5;
  border-radius: 10px;
  opacity: 1;
  margin: auto;
}
</style>