<template>
    <v-container>

        <br>
        <br>

        <div :class="image">
            <v-img contain max-height="100" :src="infoImage" max-width="100" style="margin:auto;" />
        </div>

        <br>
        <br>

        <div :class="image">
            <div class="text-center">
                <label
                    style="font-family: Arial; font-style: normal; font-weight: 400; font-size: 20px; line-height: 20px; text-align: center; color: #232323; opacity: 0.75;">
                    Tu transacción a sido procesada
                </label>

                <br>
                <br>

                <label
                    style="font-family: Arial; font-style: normal; font-weight: 400; font-size: 15px; line-height: 20px; text-align: center; color: #232323; opacity: 0.75;">
                    Dentro de las próximas horas podrás visualizar tu póliza en tu email asociado.
                </label>
            </div>
        </div>

        <br>
        <br>

        <div class="text-center">
            <v-btn style="padding: 12px 15px; gap: 10px; width: 345px; height: 50px; background: #138468; box-shadow: 4px 8px 24px rgba(51, 51, 204, 0.12);
                            border-radius: 12px; text-transform:inherit; color: white;" elevation="0"
                @click="returnPage()">
                <v-icon color="white" dark left>
                    mdi-arrow-left
                </v-icon>
                Volver
            </v-btn>
        </div>

        <br>

    </v-container>
</template>

<script>
import infoImage from "@/assets/images/check.png";

export default {
    data() {
        return {
            image: null,
            infoImage,
            screen: window.screen.width,
            path: null,
        }
    },
    async mounted() {
        const query = this.$route.query;
        this.path = query.path
        if (this.screen > 800) {
            this.image = 'image'
        } else {
            this.image = 'image2'
        }
    },
    methods: {
        returnPage() {
            window.location.href = process.env.VUE_APP_DOMINIO + this.path;
        }
    }

}
</script>

<style lang="css" scoped>
.v-alert {
    position: fixed;
    left: 50%;
    bottom: 50px;
    transform: translate(-50%, 100%);
    margin: 0 auto;
}

.image {
    width: 30%;
    margin: auto;
}

.image2 {
    width: 100%;
    margin: auto;
}
</style>
